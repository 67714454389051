import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";

// Reexport XhrStatus and useAjax(), so that people can import everything AJAX-related that they need from this file,
// instead of having to import some AJAX-related things from this file and some AJAX-related things from @bosch/bt-ui.
export { XhrStatus, useAjax } from "@bosch/bt-ui";

import { XhrStatus, useAjax } from "@bosch/bt-ui";

import {
  useOAuthAccessTokenCookie,
  useOAuthRefreshTokenCookie,
  useOAuthAccessTokenDurationCookie,
  useRememberMeCookie,
  useOAuthRefreshTokenDurationCookie,
} from "./BoschBtUiTemplateProjectUtil";
import { getDefaultCookieSettings } from "./Util";

const GET_NEW_ACCESS_TOKEN_NUM_MILLISECONDS_BEFORE_EXPIRATION = 30000;

export const DEFAULT_OAUTH_PAYLOAD = Object.freeze({
  client_id: OAUTH_CLIENT_ID,
});
export const DEFAULT_OATH_HEADERS = Object.freeze({
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: "Basic " + btoa(OAUTH_CLIENT_ID + ":" + OAUTH_CLIENT_SECRET),
});

const OAuthAccessTokenContext = React.createContext();

export const OAuthAccessTokenContextProvider = OAuthAccessTokenContext.Provider;

export function useOAuthAccessToken() {
  return useContext(OAuthAccessTokenContext);
}

export function useHandleOAuthResponse(
  rememberMe,
  oauthRequestStatus,
  oauthResult
) {
  const [
    oauthAccessTokenCookie,
    setOAuthAccessTokenCookie,
    removeOAuthAccessTokenCookie,
  ] = useOAuthAccessTokenCookie();
  const [
    oauthRefreshTokenCookie,
    setOAuthRefreshTokenCookie,
    removeOAuthRefreshTokenCookie,
  ] = useOAuthRefreshTokenCookie();
  const [
    oauthAccessTokenDurationCookie,
    setOAuthAccessTokenDurationCookie,
    removeOAuthAccessTokenDurationCookie,
  ] = useOAuthAccessTokenDurationCookie();
  const [
    oauthRefreshTokenDurationCookie,
    setOAuthRefreshTokenDurationCookie,
    removeOAuthRefreshTokenDurationCookie,
  ] = useOAuthRefreshTokenDurationCookie();

  const oauthRequestComplete = oauthRequestStatus === XhrStatus.Finished;

  const oauthAccessToken = oauthRequestComplete
    ? oauthResult.access_token
    : null;
  const oauthRefreshToken = oauthRequestComplete
    ? oauthResult.refresh_token
    : null;

  const oauthRequestSucceeded =
    oauthAccessToken != null && oauthRefreshToken != null;
  const oauthRequestFailed =
    oauthRequestStatus !== XhrStatus.Initial &&
    oauthRequestStatus !== XhrStatus.InProgress &&
    !oauthRequestSucceeded;

  useEffect(() => {
    if (!oauthRequestSucceeded) return;

    const cookieExpiresTime = rememberMe
      ? addMillisecondsToDate(new Date(), REMEMBER_ME_LENGTH_MILLISECONDS)
      : 0;

    const cookieSettings = {
      ...getDefaultCookieSettings(),
      expires: cookieExpiresTime,
    };

    setOAuthAccessTokenCookie(oauthAccessToken, cookieSettings);
    setOAuthRefreshTokenCookie(oauthRefreshToken, cookieSettings);
    setOAuthAccessTokenDurationCookie(oauthResult.expires_in, cookieSettings);
    setOAuthRefreshTokenDurationCookie(
      oauthResult.refresh_expires_in,
      cookieSettings
    );
  }, [
    oauthRequestSucceeded,
    oauthAccessToken,
    oauthRefreshToken,
    oauthResult,
    rememberMe,
    setOAuthAccessTokenCookie,
    setOAuthRefreshTokenCookie,
    setOAuthAccessTokenDurationCookie,
    setOAuthRefreshTokenDurationCookie,
  ]);

  useEffect(() => {
    if (!oauthRequestFailed) return;

    removeOAuthAccessTokenCookie();
    removeOAuthRefreshTokenCookie();
    removeOAuthAccessTokenDurationCookie();
    removeOAuthRefreshTokenDurationCookie();

    window.location = "/login";
  }, [
    oauthRequestFailed,
    removeOAuthAccessTokenCookie,
    removeOAuthRefreshTokenCookie,
    removeOAuthAccessTokenDurationCookie,
    removeOAuthRefreshTokenDurationCookie,
  ]);

  return [oauthRequestSucceeded, oauthRequestFailed];
}
function refreshOAuthAccessToken(oauthRefreshToken, doRefreshAjax) {
  const authPayload = {
    ...DEFAULT_OAUTH_PAYLOAD,
    grant_type: "refresh_token",
    refresh_token: oauthRefreshToken,
  };

  doRefreshAjax(
    "POST",
    OAUTH_TOKEN_ENDPOINT_URL,
    null,
    authPayload,
    DEFAULT_OATH_HEADERS
  );
}
export function useAutoRefreshingOAuthAccessToken() {
  const [
    oauthAccessTokenCookie,
    setOAuthAccessTokenCookie,
    removeOAuthAccessTokenCookie,
  ] = useOAuthAccessTokenCookie();
  const [
    oauthAccessTokenDuration,
    setOAuthAccessTokenDurationCookie,
    removeOAuthAccessTokenDurationCookie,
  ] = useOAuthAccessTokenDurationCookie();
  const [
    oauthRefreshToken,
    setOAuthRefreshTokenCookie,
    removeOAuthRefreshTokenCookie,
  ] = useOAuthRefreshTokenCookie();
  const [rememberMe, setMemberMeCookie, removeRememberMeCookie] =
    useRememberMeCookie();

  const hasOauthAccessTokenCookie = oauthAccessTokenCookie != null;

  const [refreshResult, refreshStatus, refresh, refreshResponse] = useAjax();
  const [refreshSucceeded, refreshFailed] = useHandleOAuthResponse(
    rememberMe,
    refreshStatus,
    refreshResult
  );

  // Refresh the access token as soon as the page is loaded, in case the access token is already expired.
  const shouldImmediatelyRefresh =
    refreshStatus === XhrStatus.Initial && oauthRefreshToken != null;

  useEffect(() => {
    if (shouldImmediatelyRefresh) {
      refreshOAuthAccessToken(oauthRefreshToken, refresh);
    }
  }, [shouldImmediatelyRefresh, oauthRefreshToken, refresh]);

  // Refresh the token a few seconds before it expires.
  useEffect(() => {
    if (oauthRefreshToken == null) return;

    const timeoutDuration = Math.max(
      oauthAccessTokenDuration * 1000 -
        GET_NEW_ACCESS_TOKEN_NUM_MILLISECONDS_BEFORE_EXPIRATION,
      GET_NEW_ACCESS_TOKEN_NUM_MILLISECONDS_BEFORE_EXPIRATION
    );

    const timeoutId = window.setTimeout(
      () => refreshOAuthAccessToken(oauthRefreshToken, refresh),
      timeoutDuration
    );

    return () => clearTimeout(timeoutId);
  }, [
    oauthAccessTokenCookie,
    oauthAccessTokenDuration,
    oauthRefreshToken,
    refresh,
  ]);

  return refreshStatus === XhrStatus.InProgress ||
    refreshStatus === XhrStatus.Error ||
    shouldImmediatelyRefresh
    ? null
    : oauthAccessTokenCookie;
}
export function useAuthenticatedMarbleAjax(
  initialStatus,
  expectsJsonResponse = true
) {
  const oauthAccessToken = useOAuthAccessToken();
  const [result, status, error, runAjax] = useAjax(
    initialStatus,
    expectsJsonResponse
  );
  const [ajaxInfo, setAjaxInfo] = useState();

  // When the user tries to perform an AJAX request, we queue it instead of immediately executing it.
  const runMarbleAjax = useCallback(
    (method, url, urlParams, content, headers) => {
      setAjaxInfo({
        method: method,
        url: url,
        urlParams: urlParams == null ? null : { ...urlParams },
        content: content,
        headers: { ...headers },
      });
    },
    []
  );

  // If the OAuth access token is being refreshed, then we wait until the refresh is finished before performing the AJAX request.
  useEffect(() => {
    if (oauthAccessToken == null || ajaxInfo == null) return;

    const authenticatedHeaders = {
      authorization: "Bearer " + oauthAccessToken,
      ...ajaxInfo["headers"],
    };

    runAjax(
      ajaxInfo["method"],
      ajaxInfo["url"],
      ajaxInfo["urlParams"],
      ajaxInfo["content"],
      authenticatedHeaders
    );

    setAjaxInfo(null);
  }, [oauthAccessToken, ajaxInfo, runAjax]);

  // If the user is trying to do an AJAX request but we don't yet have a valid OAuth access token,
  // pretend that the user's AJAX request is in progress.
  if (oauthAccessToken == null && ajaxInfo != null)
    return [null, XhrStatus.InProgress, null, runMarbleAjax];

  return [result, status, error, runMarbleAjax];
}
