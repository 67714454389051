import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import { TranslatedString } from "@bosch/bt-ui";

import withErrorHandler from "../components/ErrorHandler";
import LoggedInPages from "./LoggedInPages";
import PageNotFoundPage from "../pages/PageNotFoundPage";
import LoginPage from "../pages/LoginPage";
import LoginPageLayout from "../layouts/LoginPageLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import HomePage from "../pages/HomePage";
import { useToken } from "../components/useToken";
import { prepareLogData } from "../components/Util";

function getReturnUrl(queryString) {
  const queryParams = new URLSearchParams(queryString);

  const returnUrl = queryParams.get("return-url");

  if (returnUrl === "" || returnUrl === "/login" || returnUrl === "/logout")
    return null;

  return returnUrl;
}

const Page = () => {
  const { token, deleteToken, setToken, isLoggedIn } = useToken();
  const [isDirty, setIsDirty] = useState(false);

  const returnUrl = getReturnUrl(location.search);

  const baseRouter = new URL(MOBY_API_URL).pathname;

  // When keycloak logout success, redirect to /logout path to delete cookie after
  useEffect(() => {
    if (
      window.location.pathname === "/logout" ||
      window.location.pathname === baseRouter + "/logout"
    ) {
      if (isLoggedIn()) {
        deleteToken();
      }
      const data = prepareLogData(token, "HomePage", "UserLogOut");

      fetch(MOBY_API_URL + "/api/activity/v1/activitylog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.token,
        },
        body: JSON.stringify(data),
      });
      //Add activity log
    }
  }, [
    deleteToken,
    isLoggedIn,
    returnUrl,
    token.authenticated,
    baseRouter,
    token,
  ]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/*"
        element={
          <Routes>
            <Route
              path="/login"
              element={
                isLoggedIn() ? (
                  <Navigate to={returnUrl || "/"} replace />
                ) : (
                  <LoginPageLayout>
                    <LoginPage token={token} setToken={setToken} />
                  </LoginPageLayout>
                )
              }
            />
            <Route
              path="/logout"
              element={
                isLoggedIn() ? (
                  <Navigate to={"/logout"} replace />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="/"
              exact
              element={
                <DefaultLayout
                  token={token}
                  activeMenuId="home-page"
                  pageTitle={<TranslatedString stringId="title-home-page" />}
                  isDirty={isDirty}
                  setIsDirty={setIsDirty}
                >
                  <HomePage token={token} parentUrl="/" />
                </DefaultLayout>
              }
            />
            <Route
              path="/*"
              exact
              element={
                <LoggedInPages
                  token={token}
                  deleteToken={deleteToken}
                  isLoggedIn={isLoggedIn}
                >
                  <Routes></Routes>
                </LoggedInPages>
              }
            />
            <Route element={<PageNotFoundPage />} />
          </Routes>
        }
      />
    )
  );

  return <RouterProvider router={router} />;
};

export default withErrorHandler(React.memo(Page));
