import React, { useEffect } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddNewPage from "./AddNewPage/AddNewPage";
import ManageExistingPage from "./ManageExistingPage/ManageExistingPage";
import { TranslatedString } from "@bosch/bt-ui";
import withErrorHandler from "../components/ErrorHandler";

import "./HomePage.css";

const HomePage = (props) => {
  const { token } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (!token.authenticated) {
      navigate("/login");
    }
  }, [token.authenticated, navigate]);

  return (
    <div id="home-page">
      <h2 className="font-large"></h2>
      {token.token ? (
        <div className="content-wrapper">
          <Container className="mt-3">
            <Tabs
              defaultActiveKey="add-new"
              id="uncontrolled-tab-example"
              className="custom-tabs"
              fill
            >
              <Tab eventKey="add-new" title="Invite New Users">
                <AddNewPage />
              </Tab>
              <Tab eventKey="manage-existing" title="Manage Existing">
                <ManageExistingPage />
              </Tab>
            </Tabs>
          </Container>
        </div>
      ) : (
        <h1 className="font-large">
          <TranslatedString stringId="unauthorized-description" />
        </h1>
      )}
    </div>
  );
};

export default withErrorHandler(React.memo(HomePage));
