import React, { useMemo, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
// import TableFilter from "../../../components/TableFilter";
import { useAddNewStore } from "../../../store/add_new_store";

const fetchCompanyData = async (token) => {
  const response = await fetch(
    MOBY_API_URL +
      "/api/usermanager/v1/users/" +
      token?.user?.sub +
      "/companies",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.token,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const fetchPanelsList = async (token, companyId) => {
  const response = await fetch(
    MOBY_API_URL + "/api/companymanager/v1/companies/" + companyId + "/devices",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.token,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const PanelsList = ({ token }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const {
    selectedPanels,
    setSelectedPanels,
    selectedPanelsRow,
    setSelectedPanelsRow,
  } = useAddNewStore();

  const {
    data: companyData,
    isLoading: fetchCompanyLoading,
    isError: fetchCompanyError,
  } = useQuery({
    queryKey: ["companyData"],
    queryFn: () => fetchCompanyData(token),
    enabled: !!token?.user?.sub,
  });

  const companyId = useMemo(() => {
    if (companyData?.data?.length > 0) {
      return companyData.data[0]?.companyId || null;
    }
    return null;
  }, [companyData]);

  const {
    data: panelsList,
    isLoading: fetchListLoading,
    isError: fetchListError,
  } = useQuery({
    queryKey: ["panelsList", companyId],
    queryFn: () => fetchPanelsList(token, companyId),
    enabled: !!companyId,
  });

  const columns = useMemo(
    () => [{ accessorKey: "cloudId", header: "Cloud ID" }],
    []
  );

  const table = useReactTable({
    data: panelsList?.data || [],
    columns,
    state: { globalFilter, rowSelection },
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: "auto",
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getRowId: (row) => row.deviceId,
  });

  useEffect(() => {
    const selectedRows = table
      .getSelectedRowModel()
      .rows.map((row) => row.original);

    if (JSON.stringify(selectedRows) !== JSON.stringify(selectedPanels)) {
      setSelectedPanels(selectedRows);
    }

    const newSelectedPanelsRow = Object.fromEntries(
      selectedRows.map((panel) => [panel.deviceId, true])
    );
    if (
      JSON.stringify(newSelectedPanelsRow) !== JSON.stringify(selectedPanelsRow)
    ) {
      setSelectedPanelsRow(newSelectedPanelsRow);
    }
  }, [
    rowSelection,
    setSelectedPanels,
    setSelectedPanelsRow,
    table,
    selectedPanels,
    selectedPanelsRow,
  ]);

  const handleSelectAll = () => {
    if (Object.keys(rowSelection).length === (panelsList?.data?.length || 0)) {
      setRowSelection({});
      setSelectedPanelsRow({});
    } else {
      const allRowIds = Object.fromEntries(
        table.getRowModel().rows.map((row) => [row.id, true])
      );
      setRowSelection(allRowIds);
      setSelectedPanelsRow(allRowIds);
    }
  };

  if (fetchCompanyLoading) return <div>Loading companies...</div>;
  if (fetchListLoading) return <div>Loading panels...</div>;
  if (fetchCompanyError) return <div>Couldn't find company data</div>;
  if (fetchListError) return <div>Couldn't find panels list</div>;

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h4>
            Available Panels for Invite (find and "click" on panel below to add
            to panel invite list above):
          </h4>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex">
          {/* <div className="flex-grow-1 me-3">
            <TableFilter
              filterValue={globalFilter}
              setFilter={setGlobalFilter}
            />
          </div> */}
          <Button variant="primary" onClick={handleSelectAll}>
            {Object.keys(rowSelection).length ===
            (panelsList?.data?.length || 0)
              ? "Deselect All"
              : "Select All"}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Table bordered hover variant="info">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => row.toggleSelected()}
                  style={{
                    backgroundColor: row.getIsSelected()
                      ? "lightblue"
                      : "white",
                    cursor: "pointer",
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        backgroundColor: "inherit",
                        cursor: "pointer",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default PanelsList;
