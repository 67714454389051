import React, { useState, useEffect, useReducer, useCallback } from "react";

import { TranslationsContextProvider } from "@bosch/bt-ui";

import {
  XhrStatus,
  useAutoRefreshingOAuthAccessToken,
  OAuthAccessTokenContextProvider,
} from "../components/MobyAjax";
import Page from "./Page";
import englishTranslations from "../../translations/en-US.json";
import { useCurrentLanguage } from "../components/BoschBtUiTemplateProjectUtil";
import { DefaultLayoutContextProvider } from "../layouts/DefaultLayout";

const SiteTranslations = Object.freeze({
  "en-US": englishTranslations,
});

const AppCore = (props) => {
  const [
    isDefaultNavigationSideNavigationOpen,
    setIsDefaultNavigationSideNavigationOpen,
  ] = useState(true);

  const currentLanguage = useCurrentLanguage();

  const translations = SiteTranslations[currentLanguage];

  const oauthAccessToken = useAutoRefreshingOAuthAccessToken();

  return (
    <TranslationsContextProvider value={translations}>
      <OAuthAccessTokenContextProvider value={oauthAccessToken}>
        <DefaultLayoutContextProvider
          value={{
            isSideNavigationOpen: isDefaultNavigationSideNavigationOpen,
            setIsSideNavigationOpen: setIsDefaultNavigationSideNavigationOpen,
          }}
        >
          <Page />
        </DefaultLayoutContextProvider>
      </OAuthAccessTokenContextProvider>
    </TranslationsContextProvider>
  );
};

export default React.memo(AppCore);
